import React, { useState } from "react";
  
export const ModeContext = React.createContext([]);

const ModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);

  // const setDarkModeWrapper = (param) => {
  //   console.log(`called setDarkMode() with param (#sdfasdf3r2w): "`, param);
  //   return setDarkMode(param);
  // }

  return (
    <ModeContext.Provider value={[darkMode, setDarkMode]}>
      {children}
    </ModeContext.Provider>
  );
};

export default ModeProvider;
