import React, { useState } from "react";
import { default as axiosGetProduct } from '../requests/GetProduct'

export const ProductsContext = React.createContext({
  products: {}
});

const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState({})

  // adds / updates the product state (with the product provided)
  // (note: this gets sent into an axios request as (onSuccess) - to update the products array)
  const addOrUpdateProduct = (data) => {
    // var newProducts = {...products}
    const newProd = {
      price:      data.price,
      reference:  data.reference,
      title:      data.title
    }
    // newProducts[data.reference] = newProd

    setProducts((prevState) => {
      return {
        ...prevState,
        [data.reference]: newProd 
      }
    })

    return newProd
  }
  
  console.log('[ProductsProvider] products: ', products)

  // checks the ProductsContext for the product details first, if it doesn't exist, then request it from the backend server
  const getProduct = (reference, onSuccess, forceUpdate = false) => {

    if (forceUpdate != true) {
      // check for product details first
      const product = getProductFromState(reference)
      if (product) {
        console.log('[getProduct] got product: "' + reference + '" from state.')
        onSuccess(product)
        return product
      }
    }

    // request product from backend server
    const newOnSuccess = (requestData) => {
      console.log('[getProduct] requesting product: "' + reference + '" from backend server (as it is not currently in state - or forceUpate is = true).')
      const newProd = addOrUpdateProduct(requestData)
      onSuccess(newProd)
    }

    return axiosGetProduct(reference, newOnSuccess)
  }

  const getProductFromState = (reference) => {
    return products[reference]
  }

  return (
    <ProductsContext.Provider value={{
        products,
        getProduct
    }}>
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsProvider;