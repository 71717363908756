import React from "react"
import ModeProvider from "./src/context/ModeProvider"
import CurrentUserProvider from "./src/context/CurrentUserProvider";
import CartProvider from "./src/context/CartProvider"
import ProductsProvider from "./src/context/ProductsProvider"
import ProgrammticForm from "./src/context/ProgrammaticFormProvider"
import DiscountProvider from "./src/context/DiscountProvider";

export const wrapRootElement = ({ element }) => (
  <ModeProvider>
    <CurrentUserProvider>
      <ProductsProvider>
        <CartProvider>
          <DiscountProvider>
            <ProgrammticForm>
              {element}
            </ProgrammticForm>
          </DiscountProvider>
        </CartProvider>
      </ProductsProvider>
    </CurrentUserProvider>
  </ModeProvider>
) 