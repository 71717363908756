import React, { useState } from "react";
  
export const ProgrammaticFormContext = React.createContext([]);

// this contains validation funcs and form fieldValues
// note: this was created as <PaymentForm> needed access to fieldValues (to send them using its custom onSubmit handler) and validation (as
// the standard onSubmit handler and button is overriden). These items cannot be "passed up" to <PaymentForm>.
const ProgrammaticFormProvider = ({ children }) => {
  const [fieldValues, setFieldValues]   = useState({})
  const [errorMsgs, setErrorMsgs]       = useState([]);

  // set a error message for display directly below a form field
  // it will override any pre-existing error msg
  const setFieldErrorMsg = (newErrorMsg, fieldName) => {
      // console.log('[setFieldErrorMsg] yyy3 fieldName: ', fieldName)
      if (!newErrorMsg) {
          return null
      }
      // console.log('[setFieldErrorMsg] yyy3 newErrorMsg: ', fieldName)
      var newFieldErrorMsgs = {...errorMsgs}
      newFieldErrorMsgs[fieldName] = newErrorMsg

      setErrorMsgs(newFieldErrorMsgs)
  }

  // validate each fields user input against the 'validation' rules
  const validateUserInput = (formFields) => {
    // console.log('formFields #yy6: ', formFields)
      var newFieldErrorMsgs = {}
      var isThereFormErrors = false
      Object.entries(formFields).map(([fieldName, field]) => {
          const newErrorMsg = validateField(fieldName, formFields, false)
          if (newErrorMsg) {
              isThereFormErrors = true
              newFieldErrorMsgs[fieldName] = newErrorMsg
          }
          
      })
      setErrorMsgs(newFieldErrorMsgs)

      return (isThereFormErrors) ? 'There were errors in your form submission. Please fix and then resubmit the form.' : true
  }

  // validate the user input of one field (against the fields rules)
  const validateField = (fieldName, formFields, updateErrorState = true) => {
      const field = formFields[fieldName]
      const curFieldValue = fieldValues[fieldName]
      if (!field) {
          throw new Error ('unknown form field: ' + fieldName)
      }
      const validation = field.validation

      var isThereFormErrors

      if (validation) {
          console.log('#sdfwsfd curFieldValue: ', curFieldValue)
          var errorMsg = null
          // test required
          if (validation.required && !curFieldValue) {
              errorMsg = 'this field is required.'
              isThereFormErrors = true
          }

          // console.log('#34wesfd: maxlength: ' + validation.maxLength + ' string length: ' + curFieldValue.length)
          // test maxLength
          if (validation.maxLength && !(curFieldValue.length <= validation.maxLength)) {
              errorMsg = 'this field must be ' + validation.maxLength + ' characters long at most. (Your entry is ' + curFieldValue.length + ' characters long)'
              isThereFormErrors = true
          }

          // test minLength
          if (validation.minLength && !(curFieldValue.length >= validation.minLength)) {
              errorMsg = 'this field must be at least ' + validation.minLength + ' characters long. (Your entry is ' + curFieldValue.length + ' characters long)'
              isThereFormErrors = true
          }
          if (updateErrorState) {
              setFieldErrorMsg(errorMsg, fieldName)
          }
      }

      return errorMsg 

  }

  return (
    <ProgrammaticFormContext.Provider value={{
        fieldValues,
        setFieldValues,
        errorMsgs,
        setErrorMsgs,
        setFieldErrorMsg,
        validateUserInput,
        validateField
    }}>
      {children}
    </ProgrammaticFormContext.Provider>
  );
};

export default ProgrammaticFormProvider;