const axios = require('axios');

const getProduct = async (reference, onSuccess) => {
    const requestedURL = 'http://localhost:8001/api/products/' + reference

    // const response = 
    await axios.get(requestedURL)
    .then((response) => {
        
        const product = response.data
        
        if (onSuccess) {
            onSuccess(product)
        }

        console.log('[GetProduct] result: ', product)
      }).catch((e) => {
        alert('Error: unable to get product information from server.')
        console.log('[GetProduct] error: ', e)
      });

    return 'xyz -- getProduct return data'
}

export default getProduct