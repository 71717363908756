import React from "react"

export const centsToDollars = (cents) => {
    return (cents/100).toLocaleString("en-US", {style:"currency", currency:"AUD"})
}

// return the total price of all items and quantites - without a discount coupon applied.
export const totalPriceInCents = (cartItems) => {
  var total = 0
  console.log('[totalPriceInCents] cartItems: ', cartItems)
  Object.keys(cartItems).map((k) => {
    const item = cartItems[k]
    total = total + (item.price * item.quantity)
  })

  return total
}

export const getTotalWithDiscount = (cartItems) => {
    return totalPriceInCents (cartItems) - getDiscountTotal(cartItems)
}

export const getDiscountTotal = (cartItems) => {
    var discountTotalInCents = 0
    // if (!discountData) {
    //     // only if there's no discount coupon being applied - should this return null (to indicate: do not display this)
    //     return null
    // }
    console.log('[getDiscountTotal] cartItems: ', cartItems)

    Object.keys(cartItems).map((k) => {
        const curItem = cartItems[k]
        console.log('[getDiscountTotal] curItem: ', curItem)
        discountTotalInCents = discountTotalInCents + curItem.totalDiscountInCents
    })
    
    console.log ('[getDiscountTotal] discountTotalInCents: ', discountTotalInCents)

    return discountTotalInCents
}