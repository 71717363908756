import { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

// this queries for siteSettings, normalizes (and checks the input from the CMS) and puts
// the result into an easy to use variable: ss

// query the 'settings' CMS entity, test each item against "acceptable options" (to check for mispellings / wrong settings value when the marketing person
// is updating settings) and then build a "allSettings" object
export const useGetSiteSettings = () => {
  
  const [ siteSettings, setSiteSettings ] = useState(null)
  
  const settingsResult = useStaticQuery(graphql`
    query AllSettingsQuery {
      allContentfulSetting {
        edges {
          node {
            id
            value
            settingCode
          }
        }
      }
    }
  `)
  // console.log('settingsResult [#jk8]: ', settingsResult)

  if (siteSettings) { 
    return siteSettings
  }

  // a list of acceptable settings options and a default (in case the provided value doesn't match an option.)
  const acceptedOptions = {
    homepageDisplayMode: {
      default: homepageDisplayModeOptions.dates,
      acceptedOptions: homepageDisplayModeOptions
    }
  }

  // todo: check if default values are accepted too!

  const ss = {}
  console.group('building siteSettings [#ss1]')
  settingsResult.allContentfulSetting.edges.map((curItem) => {
    let { node: {settingCode, value} } = curItem
    
    // console.log('node: ', node)
    const curCode = acceptedOptions[settingCode]
    if (curCode) {
      // check if the option is acceptable
      if (!curCode.acceptedOptions[value]) {
        console.log(
          'settingCode: ' + settingCode + ', with value: ' + value + ', is not an "accepted" option value. Accepted option values are: ',
          curCode.acceptedOptions + ', so we fell back to the default value: ' + curCode.default
        )
        value = curCode.default
      } else {
        console.log('settingCode: ' + settingCode + ', with value: ' + value + ' is accepted')
      }
    } else {
      console.warn('[#ss1] No setting options exist for setting with code: ' + settingCode )
    }

    ss[settingCode] = value
  })
  console.groupEnd()

  console.log('FINAL siteSettings [#ss9]: ', ss)

  // ss.priceInflateInCents = 0 
  
  setSiteSettings(ss)
  return ss
}

// possible options for this setting:
export const homepageDisplayModeOptions = {
  dates: 'dates',
  allEventTypes: 'allEventTypes'
}

const applyDSTcorrection = () => {
  console.log('debugSettings.doNotapplyDSTcorrection: ', debugSettings.doNotapplyDSTcorrection)
  if ((debugSettings.doNotapplyDSTcorrection == true) && (process.env.NODE_ENV == "development")) {
    return false
  } else {
    return true
  }
}

// parameters that can be configured (or development only)
// e.g. shift todays date forward 150 days, so it triggers the start of daylightsavings (to enable debugging)
// why does this exist? so you don't have to keep toggling between modes and possibly forget code that will cause bugs in production.
// usage tip: if you see something off when writing code, check here first, as it may acctually be a setting 
// usage tip #2: always wrap the code (affecting the app) in a if (process.env='dev') to prevent it from affecting prod!
// usage tip #3: "turn off" these debug settings after use - as you will forget it's in effect while in dev and chase your tail trying to find out why.
// --- UPDATE: yup, I've already wastes 2 hours chasing a "bug" - it generated unexpected behaviour on another page, so I didn't make the link it was related to this feature.
export const debugSettings = {
  repeatEventCreationDaysShift: 0,// 30*7, // april (DST start), // 6 * 7, // oct (DST end),              // the number of days to add to the startDate of a repeat=weekly eventDate - when generating a list of all future event dates (good for debugging daylight savings issues for instance)
  // [#wr8--]
  // doNotapplyDSTcorrection: true,                    // when true, this will prevent the day light savings *offset* correction from being applied

  // functions:
  applyDSTcorrection: applyDSTcorrection
}