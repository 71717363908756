import axios from "axios";

// get the discount coupon from the backend server
const requestDiscountCoupon = (couponCode, onSuccess, onError) => {
    console.log('[VerifyDiscountCoupon] coupon: (#33re): ', couponCode)

    // send axios request
    const requestedURL = 'http://localhost:8001/api/coupons/' + couponCode
    const config = {
        withCredentials: true
    };
    const response = axios.get(requestedURL, config)
    response.then((response) => {
        const data = response.data
        console.log ('axios response from VerifyDiscountCoupon() (#33dsc): ', data)

        onSuccess(data)
    },
    (error) => {
        console.error('[VerifyDiscountCoupon] axios ERROR: (#zzasd2): ', error.response);
        onError(error)
    }).catch((e) => {
        console.error('[VerifyDiscountCoupon] axios ERROR: (#sdcw34f): ', e);
    })
}

export default requestDiscountCoupon