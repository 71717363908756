import React, { useState, useContext, useEffect } from "react";
import requestDiscountCoupon from '../requests/requestDiscountCoupon'
import queryString from 'query-string'
import { ProductsContext } from "../context/ProductsProvider"
import dateFormat from "dateformat";

const initialState = { items: {
  
} }

// note: CartProvider is currently not used - it was designed for OTA features

export const CartContext = React.createContext({
  initialState
})

const hydrateFromMemory = (varName) => {
  if (typeof window == `undefined`) {
    return {}
  }

  const cartMem = localStorage.getItem(varName)
  console.log('[hydrateFromMemory] "' + varName + '": "' + cartMem + '"')
  if (cartMem == 'undefined' || !cartMem) {
    return {}
  }
  
  return JSON.parse(cartMem)
}

const getSavedCouponCode = () => {
  const cc = hydrateFromMemory('couponCode')
  if (cc) {
    // zzz?
  }

  return cc
}

const CartProvider = ({ children }) => {
  const { getProduct } = useContext(ProductsContext)
  const [cartItems, realSetCartItems] = useState(() => hydrateFromMemory('cartItemsMem'))
  // const [couponCode, setCouponCode] = useState(getSavedCouponCode(couponCode, couponAppliedStatus))
  const [couponCode, setCouponCode] = useState(getSavedCouponCode())
  const [discountData, setDiscountData] = useState(null)   // discount data from the backend server (see: postman: Coupon > ​/api​/coupons/??)
  const [couponAppliedStatus, setCouponAppliedStatus] = useState(null);
  var onCouponChangeHandler = (a,b) => {}

  // saves the cart items to localStorage as well
  const setCartItems = (newCartItems) => {
    if (typeof newCartItems === 'function') {
      throw Error ('do not send a function to setCartItems()');
    }
    console.log('[setCartItems]: ', newCartItems)
    localStorage.setItem('cartItemsMem', JSON.stringify(newCartItems))

    realSetCartItems(() => newCartItems)
  }

  // use the cart total function - not this one!
  const totalPrice = (includeCurrency = false) => {
    var total = 0
    console.log('[totalPrice] #234eeasd: ', cartItems)
    Object.keys(cartItems).map((k) => {
      const item = cartItems[k]
      console.log('[totalPrice] price: ' + item.price + ', quantity: ' + item.quantity)
      total = total + (item.price * item.quantity)
    })
    const currencyPart = (includeCurrency) ? ' AUD' : ''

    return '$' + total.toFixed(2) + currencyPart
  }

  const totalItems = () => {
    var total = 0
    Object.keys(cartItems).map((k) => {
      const item = cartItems[k]
      total = total + item.quantity
    })

    return total
  }

  // quantity adjuster handler for the checkout page
  const handleOnAdjustQuantity = (targetItem, amountChange) => {
      const newItems = {}
      Object.keys(cartItems).map((k) => {
          const item = cartItems[k]
          // console.log('[handleOnAdjustQuantity] ', targetItem)
          if (targetItem.reference == item.reference) {
              // prevent negative amounts
              if (item.quantity < 1 && amountChange < 0) {
                item.quantity = 0
              } else {
                item.quantity = item.quantity + amountChange
              }
          }
          
          newItems[k] = item
      })

      updateCartItems(newItems)

      // setCartItems(newCartItems2)
  }

  // forces error checking and applies discount coupon (if it exists)
  const updateCartItems = (newCartItems) => {
    console.log('[updateCartItems] newCartItems (#fdvosdf): ', newCartItems)
    const newCartItems2 = calculateDiscounts(discountData, newCartItems)
    console.log('[updateCartItems] newCartItems2 (#fdvosdf): ', newCartItems2)

    const noErrors = checkForZeroQuantityItems(newCartItems2)
    if (noErrors) {
      setCartItems(newCartItems2)
    }
  }

  // this allows you to set incorrect prices and then test the apps ability to contact the backend server to update them.
  const setTestItemsWithIncorrectPrices  = (testSet) => {
    switch (testSet) {
      case 1:
        addItem (
          "Great Ocean Road - 1 day tour",
          ["Date: 16/01/2022", "Pickup location: State Library"],
          1,
          6250,
          'OTA:CVS3GFSDF4454ERGVD:0392:234849:45832',     // [OTA marker] [contentful product entry ID] [session_id] [pickupLocation] [ticketTypeId]
          'http://www.newtomelbourne.org/????',             // url to product
          {
            // 'displayGroup': 'OTA_34GERFKASI432KOZ4234_0392_234849',
            'variantLabel': 'First release ticket'
          },
          {
            disableQuantityChanger: true
          }
        )
        addItem (
          "Great Ocean Road - 1 day tour",
          ["Date: 16/01/2022", "Pickup location: State Library"],
          2,
          7100,
          'OTA:34GERFKASI432KOZ4234:0392:234849:99321',     // [OTA marker] [contentful product entry ID] [session_id] [pickupLocation] [ticketTypeId]
          'http://www.newtomelbourne.org/????',             // url to product
          {
            // 'displayGroup': 'OTA_34GERFKASI432KOZ4234_0392_234849',
            'variantLabel': 'Second release ticket'
          },
          {
            disableQuantityChanger: true
          }
        )
        addItem (
          'Wilsons Promontory',
          ["Date: 18/01/2022", "Pickup location: State Library"],
          4,
          5900,
          'OTA_Z290SFERSK_9319',                  // [OTA marker] [contentful entry ID] [session_id]
          'http://www.newtomelbourne.org/????',    // ticket id (in rezdy),
          null,
          {
            disableQuantityChanger: true
          }
        )
        break;
      case 2:
        addItem (
          "Attendee badge",
          [],
          1,
          955,
          'le_badge',
          '',
          null,
          {
            disableQuantityChanger: true
          }
        )
        addItem (
          "Attendee registration",
          [],
          1,
          1050,
          'le_registration',
          '',
          null,
          {
            disableQuantityChanger: true
          }
        )
        break;
      default:
        throw new Error ('unknown testSet: asdsdf123')
    }
  }

  const setOtaTestItems = () => {
    setTestItemsWithIncorrectPrices(1)
    // addItem (
    //   "Great Ocean Road - 1 day tour",
    //   "Date: 16/01/2022 - 8:30am pickup",
    //   2,
    //   6250,
    //   'ota_gor_1st',
    // )
  }

  // set some sample data for cartItems (for devmode only)
  const setTestItems = () => {
    // console.log('getProduct: ', getProduct)
    addItemByProductReference('le_badge', 4)
    addItemByProductReference('le_registration', 1)
  }

  // add an item to the cartItems array / "the checkout"
  const addItem = (title, subCaptions, quantity, price, reference, url, variant = null, config = null) => {
    console.log('cartItems (#wsdcs): ', cartItems)
    const newItem = {
        title,
        subCaptions,
        quantity,
        price,
        reference,
        url,
        variant,
        config
    }

    cartItems[reference] = newItem   // as we are (potentially) calling SetCartItems() more than once (before a refresh), we need to update the original too.

    updateCartItems({ ...cartItems, [reference]: newItem,  })

    console.log('Added a product to cartItems. Name: ' + title + ', quantity: ' + quantity)
  }

  // add a product to the cart items. forceUpdate=true will force a request to the backend server instead of using any (previously requested) product data in the state
  const addItemByProductReference = (reference, quantity, forceUpdate = false) => {
    if (forceUpdate != true) {

    }
    
    // get product details from ProductContext
    const onSuccess = (product) => {
      addItem (
        product.title,
        product.subCaption,
        quantity,
        product.price,
        reference
      )
    }
    
    getProduct(reference, onSuccess, forceUpdate)
    // upate cartItems
  }

  const removeLineItem = (lineItemRef) => {
    console.log ('cartItems (#sdf34): ', cartItems)
    if (!cartItems[lineItemRef]) {
      
      throw Error ('Cart item with reference: ' + lineItemRef + ' does not exist.')
    } else {
      // remove the item
      delete cartItems[lineItemRef]
      const newCartItems = {...cartItems}
      setCartItems(newCartItems)
    }
    console.log ('cartItems (#sdf34): AFTER--- ', cartItems)

    return true
  }

  const emptyCart = () => {
    console.log('emptying cart')
    setCartItems({})
  }

  // this is used to prevent the user from going to the payment page with a 0 quantity item in the checkout list (as it would throw an error in the backend).
  const checkForZeroQuantityItems = (tempCartItems) => {
    var errorExists = false
    var newCartItems = {}
    Object.keys(tempCartItems).map((k) => {
      const item = tempCartItems[k]
      if (item.quantity == 0) {
        item.errorMsg = 'This item has 0 quantity in the checkout. Please "Remove" this item, or increase its quantity.'
        errorExists = true
      } else {
        // caution! this will wipe out other error messages (if we have them in the future - from different sources)
        item.errorMsg = null
      }
      newCartItems[k] = item
    })

    if (errorExists) {
      return false
    }

    return true
  }

  // use the discount data (provided by the backend server) to calculate the discount amount (in cents) for each line item
  // (this is used as the onSuccess handler for requestDiscountCoupon())
  const calculateDiscounts = (dd, CartItemsTemp) => {
    // setisCouponApplied

    console.log('[calculateDiscounts] discount data: ', dd)
    // alert ('caluculate2134');
    console.log('cartItems: ', CartItemsTemp)

    // re-calculate discount amounts
    var newCartItems = {};
    Object.keys(CartItemsTemp).map((k) => {
      const item = CartItemsTemp[k]
      var curDiscount
      if (dd) {
        curDiscount = calculateLineItemDiscount(item, dd)
      } else {
        curDiscount = 0
      }
      console.log ('[calculateDiscounts] curDiscount: ', curDiscount)
      newCartItems[k] = {...item, totalDiscountInCents: curDiscount}

      console.log('[verifyCoupon] item: #34w5twsdv: ', item)
      // items[item.reference] = {
      //   'quantity': item.quantity
      // }
    })

    console.log('newCartItems: ', newCartItems)

    // apply discount to cartItems
    // setCartItems(newCartItems);
    return newCartItems
  }

  // apply any discounts and get the total discount amount (in cents) for the line item (which includes: * quantity)
  const calculateLineItemDiscount = (item, dd) => {
    console.log('[calculateLineItemDiscount] item: ', item )
    
    const ref = item.reference

    // search discountData for a product reference that matches the item - if one does, calculate (and return) the discount amount.
    var totalDiscountInCents = 0
    Object.keys(dd.relatedAffectedProducts).map((k) => {
      const curDiscountedProd = dd.relatedAffectedProducts[k]
      if (curDiscountedProd.reference == ref) {
        console.log ('[calculateLineItemDiscount] prouct: ' + ref + ' has a discount!')
        totalDiscountInCents = calculateDiscountPerUnit(item, dd)
        console.log ('[calculateLineItemDiscount] totalDiscountInCents: ', totalDiscountInCents)
        
        // alert ('match!')
      }
      // console.log('[calculateLineItemDiscount] curDiscountedProd: ', curDiscountedProd)
      
    })
    return (totalDiscountInCents * item.quantity)
  }

  const calculateDiscountPerUnit = (cartItem, dd) => {
    console.log('[calculateDiscount] cartItem: ', cartItem)
    console.log('[calculateDiscount] discountData: ', dd)
    var discount
    var isTwice = false
    if (dd.discountPercent != 0 && dd.discountPercent) {
      discount = (cartItem.price * dd.discountPercent / 100)
      console.log('[calculateDiscount] discount: ', discount)
      isTwice = true
    }

    if (dd.discountAmount != 0 && dd.discountAmount) {
      alert('not configured')

      if (isTwice) {
        throw new Error ('can only have discountAmount or discountPercent, not both.')
      }
    }

    return Math.round(discount)

  }

  // (when the "apply coupon" button is pressed.)
  // call the backend servers to verify coupon and get a list of products that are affected. Then apply discount.
  const verifyCoupon = (couponCode) => {
    // call backend server & process returned discount

    console.log('[verifyCoupon] couponCode: (#123zz) ', couponCode)

    localStorage.setItem('couponCode', JSON.stringify(couponCode))
    
    const onSuccess = (discData) => {
      setDiscountData(discData)
      const newCartItems = calculateDiscounts(discData, cartItems)
      setCartItems(newCartItems)
      console.log('[verifyCoupon > success] couponCode: (#123zz) ', couponCode)

      setCouponAppliedStatus(CouponAppliedOptions.couponSuccessfullyApplied)

      console.log('[verifyCoupon] invoke: onCouponChangeHandler() (#23423z) (#opw3): couponCode: ' + couponCode + ', onCouponChangeHandler: ',  onCouponChangeHandler)
      // to find all handlers, search: registerCouponStatusChangeFunc
      onCouponChangeHandler(couponCode, CouponAppliedOptions.couponSuccessfullyApplied)
    }

    // handle coupon doesn't exist or other unknown errors
    const onFailure = (error) => {
      console.log('[verifyCoupon > failure] couponCode: (#123zz) ', couponCode)
      var errorType

      if (!error.response) {
        errorType = CouponAppliedOptions.unknownError
      }
      if (error.response.status == 404) {
        errorType = CouponAppliedOptions.couponDoesNotExist
      }

      setCouponAppliedStatus(errorType)

      console.log('[verifyCoupon] invoke: onCouponChangeHandler() (#23423z): couponCode: ' + couponCode + ', onCouponChangeHandler: ',  onCouponChangeHandler)
      removeDiscountAmounts()

      // to find all handlers, search: registerCouponStatusChangeFunc
      onCouponChangeHandler(couponCode, errorType)
    }

    requestDiscountCoupon(couponCode, onSuccess, onFailure)
  }
  
  const removeDiscountAmounts = () => {
    setDiscountData(null)
    
    const newCartItems = calculateDiscounts(null, cartItems)
    console.log('[newCartItems] newCartItems (#sdfsd): ', newCartItems )
    setCartItems(newCartItems)
    
    return true
  }

  const getDiscountTotal = () => {
      var discountTotalInCents = 0
      if (!discountData) {
          // only if there's no discount coupon being applied - should this return null (to indicate: do not display this)
          return null
      }

      Object.keys(cartItems).map((k) => {
          const curItem = cartItems[k]
          console.log('[getDiscountTotal] curItem: ', curItem)
          discountTotalInCents = discountTotalInCents + curItem.totalDiscountInCents
      })
      
      console.log ('[getDiscountTotal] discountTotalInCents: ', discountTotalInCents)

      return discountTotalInCents
  }

  // returns true if a discount coupon is currently valid and active on the cart
  const isCouponActive = () => {
    if (discountData) {
      return true
    } else {
      return false
    }
  }

  const isCouponApplied = () => {
    return (cartTools.couponAppliedStatus == cartTools.CouponAppliedOptions.couponSuccessfullyApplied)
  }

  // const discountInitialValue = () => {
  //   return 
  // }

  // a list of flags that represent the state of the coupon being applied (success, coupon does not exist etc.)
  const CouponAppliedOptions = {
    couponSuccessfullyApplied: 100,
    couponDoesNotExist: 200
  }

  // (a bit complex, but): this allows a callback to be configured that allows a component (like the checkout) to update
  // it's presentation (i.e. "apply coupon" btn to disabled) after verifycoupon() is executed.
  // 
  const registerCouponStatusChangeFunc = (handler) => {
    // console.log('[registerCouponStatusChangeFunc] (#23423z) handler: ', handler)
  
    onCouponChangeHandler = handler
  }

  const addCartItem = (title, subCaptions, quantity, price, reference, url, variant = null, config = null) => {
    return addItem(title, subCaptions, quantity, price, reference, url, variant, config)
  }

  // Provide the useLocation() hook > search
  const setCouponCodeFromURL = (search) => {
    const couponCode = queryString.parse(search).couponCode
    if (couponCode) {
      console.log('[setCouponCodeFromURL]: ', couponCode)
      setCouponCode(couponCode)
      
      localStorage.setItem('couponCode', JSON.stringify(couponCode))
      verifyCoupon(couponCode)
    }
  }

  // this bundles all the functions together, so only one obj needs to be passed through (and into components) instead of 8-10
  const cartTools = {
    isCouponActive,
    isCouponApplied,
    couponAppliedStatus,
    CouponAppliedOptions,
    verifyCoupon,
    getDiscountTotal,
    registerCouponStatusChangeFunc,
    couponCode,
    setCouponCode,
    totalPrice,
    setTestItems,
    setTestItemsWithIncorrectPrices,
    setOtaTestItems,
    addCartItem
  }

  return (
    <CartContext.Provider value={{
        cartItems,
        setCartItems,
        couponCode,
        setCouponCode,
        verifyCoupon,
        totalPrice,
        totalItems,
        handleOnAdjustQuantity,
        removeLineItem,
        emptyCart,
        checkForZeroQuantityItems,
        getDiscountTotal,
        cartTools,
        setCouponCodeFromURL
    }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;