import React, { useState } from "react";

export const CurrentUserContext = React.createContext({
    firstName: null,
    setFirstName: () => {},
    email: null,
    setEmail: () => {},
    processUserResponseData: () => {}
});

const CurrentUserProvider = ({ children }) => {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');

  // const login = ({ identifier, password }) => {
    
  // }

  /**
   * Find if a user is logged in, or update the app's user contexts if not
   * note: this *must* be triggered on every request result, as a session may be terminated in another window or via the server.
   * This user data being processed on every request ensures that the user context data is up to date (without polling).
   * (accepts the (unprocessed) 'userData' field returned in the axios response and process it.)
   */ 
  const processUserResponseData  = (userData) => {
    // alert('firstName')
    var firstName2, email2
    if (userData) {
      firstName2 = userData.firstName
      email2 = userData.email
    } else {
      firstName2 = null
      email2 = null
    }

    setFirstName(firstName2)
    setEmail(email2)
    
    console.log('Finished processing axios userData (#23rwc23): ', userData)
  }

  return (
    <CurrentUserContext.Provider value={{
        firstName,
        email,
        processUserResponseData
    }}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;